import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        const scrollToTop = () => {
            setTimeout(() => {
                if (document.documentElement) {
                    document.documentElement.scrollTop = 0;
                }
                if (document.body) {
                    document.body.scrollTop = 0;
                }
            }, 10);
        };

        scrollToTop();

    }, [pathname]);

    return null;
};

export default ScrollToTop;
