import {configureStore} from '@reduxjs/toolkit';
import cartReducer from './cartReducer';
import favoriteReducer from './favoriteReducer';
import loginReducer from './loginReducer';
import promocodeReducer from './promocodeReducer';
import languageReducer from './languageReducer';
import timestampReducer from './timestampReducer';
import adminUserReducer from './adminUserReducer';

import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import createExpiringStorage from "./expiringStorage"; // defaults to localStorage for web


const persistConfigCart = {
    key: 'cart',
    version: 1,
    storage,
};

const persistConfigFavorite = {
    key: 'favorite',
    version: 1,
    storage,
};

const persistConfigLogin = {
    key: 'login',
    version: 1,
    storage,
};

const persistConfigPromocode = {
    key: 'promocode',
    version: 1,
    storage,
};
let storageTime = createExpiringStorage(24 * 60 * 60 * 1000 * 7)
const persistConfigLanguage = {
    key: 'language_v1',
    version: 1,
    storage: storageTime
};

const persistConfigTimestamp = {
    key: 'timestamp',
    version: 1,
    storage,
};

const persistConfigAdminUser = {
    key: 'adminUser',
    version: 1,
    storage,
};

// Tworzenie persisted reducers dla każdego stanu
const persistedCartReducer = persistReducer(persistConfigCart, cartReducer);
const persistedFavoriteReducer = persistReducer(persistConfigFavorite, favoriteReducer);
const persistedLoginReducer = persistReducer(persistConfigLogin, loginReducer);
const persistedPromocodeReducer = persistReducer(persistConfigPromocode, promocodeReducer);
const persistedLanguageReducer = persistReducer(persistConfigLanguage, languageReducer);
const persistedTimestampReducer = persistReducer(persistConfigTimestamp, timestampReducer);
const persistedAdminUserReducer = persistReducer(persistConfigAdminUser, adminUserReducer);

export const store = configureStore({
    reducer: {
        cart: persistedCartReducer,
        favorite: persistedFavoriteReducer,
        login: persistedLoginReducer,
        promocode: persistedPromocodeReducer,
        language: persistedLanguageReducer,
        timestamp: persistedTimestampReducer,
        adminUser: persistedAdminUserReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    devTools: true, // włącz devTools tylko w trybie deweloperskim
});

// Inicjalizacja persistor-a
export let persistor = persistStore(store);
