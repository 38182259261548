import storage from 'redux-persist/lib/storage';

const createExpiringStorage = (expireTimeMs) => ({
    async getItem(key) {
        const item = await storage.getItem(key);
        if (!item) return null;

        const parsedItem = JSON.parse(item);
        const now = Date.now();

        if (parsedItem.expiration && now > parsedItem.expiration) {
            await storage.removeItem(key); // Usuń wygasłe dane
            return null;
        }

        // Odśwież czas wygaśnięcia
        parsedItem.expiration = now + expireTimeMs;
        await storage.setItem(key, JSON.stringify(parsedItem));

        return parsedItem.value; // Zwróć wartość
    },
    async setItem(key, value) {
        const expiration = Date.now() + expireTimeMs;
        const itemToStore = JSON.stringify({value, expiration});
        await storage.setItem(key, itemToStore);
    },
    async removeItem(key) {
        await storage.removeItem(key);
    },
});


export default createExpiringStorage
