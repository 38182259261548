import React, { useState } from "react";
import "./Card.scss";
import { Link, useSearchParams } from "react-router-dom";
import slugify from "slugify";
import { useDispatch, useSelector } from "react-redux";
import { addToFavorite } from "../../Redux/favoriteReducer";
import { addToCart } from "../../Redux/cartReducer";
import axios from "axios";
import { changeCurrency } from "../../functions/changeCurrency";
import ImageTest from "../SwiperHome/imageTest";

function Card({
  product,
  type,
  profile,
  addCart,
  addFavorite,
  deleteFavorite,
}) {
  const cartFavorite = useSelector((state) => state.favorite.favorite);
  const [searchParams, setSearchParams] = useSearchParams();
  const isInCart = cartFavorite.some((item) => item.id === product.id);
  const [textFavorite, setTextFavorite] = React.useState(
    isInCart ? true : false,
  );

  const dispatch = useDispatch();

  const [controlPanel, setControlPanel] = React.useState(false);

  const [skeletonState, setSkeletonState] = React.useState(true);

  let url;

  if (product?.attributes?.title) {
    url = slugify(product?.attributes?.title, { lower: true });
  }

  const clickToFavorite = () => {
    dispatch(
      addToFavorite({
        attributes: {
          masterId: getId(product),
        },
        uniqId: product.uniqId,
        id: getId(product),
        title: product?.attributes?.title,
        img: product?.attributes?.img_card?.data?.attributes?.url,
        price: product?.attributes?.price,
      }),
    );

    setTextFavorite(!textFavorite);
  };
  const [dekorAdd, setDekorAdd] = useState(false);

  async function addToCard() {
    const materialArr = product?.attributes?.materials?.data.map(
      (materials) => materials.id,
    );
    let materialResult = {};
    if (materialArr.length > 0) {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/materials/${materialArr[0]}?populate=*`,
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
          },
        },
      );

      const materialData = resp.data.data;

      materialResult = {
        img:
          process.env.REACT_APP_IMG +
          materialData.attributes?.img?.data?.attributes?.url,
        name: materialData.attributes.name,
      };
    }

    dispatch(
      addToCart({
        id: getId(product),
        title: product?.attributes?.title,
        description: product?.attributes?.description,
        img:
          process.env.REACT_APP_IMG +
          product?.attributes?.img_card?.data?.attributes?.url,
        price: product?.attributes?.price,
        quantity: 1,
        stockQuantity: product?.attributes?.stockQuantity.toString(),
        materialArticle: "-",
        colorArticle: "-",
        modules: [],
        configurations: [],
        color: "-",
        material: materialResult,
      }),
    );

    setTimeout(() => {
      setDekorAdd(false);
    }, 2500);

    setDekorAdd(true);
  }

  const currency = useSelector((state) => state.language.language);

  function addSortParamIfExist() {
    if (searchParams.get("sort")) {
      return "?sort=" + searchParams.get("sort");
    } else {
      return "";
    }
  }

  function getId(product) {
    if (product?.attributes?.masterId) {
      return product.attributes.masterId;
    } else {
      return product.id;
    }
  }

  function returnPriceOrFalsePrice() {
    if (
      product?.attributes?.falsePrice != undefined &&
      product?.attributes?.falsePrice > 0
    ) {
      return product?.attributes?.falsePrice;
    }
    return product?.attributes?.price;
  }

  return (
    <>
      {type === "catalog" ? (
        <div
          onMouseOver={() => setControlPanel(true)}
          onMouseOut={() => setControlPanel(false)}
          className="card"
        >
          <div className="card__img-wr">
            {typeof product?.attributes?.PersonalAccountDiscountPercent ===
              "number" &&
              profile && (
                <>
                  <div className="account__discount"></div>
                  <span className="account__discount-text">
                    -{product?.attributes?.PersonalAccountDiscountPercent}%
                  </span>
                </>
              )}

            <div
              className={
                skeletonState ? "skeleton-load-img active" : "skeleton-load-img"
              }
            >
              <img src={"/img/header__logo-short.png"} />
            </div>

            <Link
              to={
                profile
                  ? "/product/profile/" +
                    url +
                    "/" +
                    getId(product) +
                    addSortParamIfExist()
                  : "/product/" +
                    url +
                    "/" +
                    getId(product) +
                    addSortParamIfExist()
              }
            >
              <ImageTest
                onClick={() => {
                  setControlPanel(!controlPanel);
                }}
                src={product?.attributes?.img_card?.data?.attributes?.url}
                alt=" "
                className="main"
                style={{
                  objectPosition:
                    product?.attributes?.miniatureImageObjectPosition,
                }}
                onLoad={() => {
                  setSkeletonState(false);
                }}
              />
            </Link>

            <div
              className={
                controlPanel ? "card__controls active" : "card__controls"
              }
            >
              {/*<span*/}
              {/*  onClick={addToCard}*/}
              {/*  style={{ position: "relative" }}*/}
              {/*  // to={'/product/' + url + '/' + product.id}*/}
              {/*>*/}
              {/*  <span*/}
              {/*    style={{ width: "min-content" }}*/}
              {/*    className={*/}
              {/*      dekorAdd*/}
              {/*        ? "addToDrawerDekor active dekor1"*/}
              {/*        : "addToDrawerDekor dekor1"*/}
              {/*    }*/}
              {/*  >*/}
              {/*    +1*/}
              {/*  </span>*/}
              {/*  <span*/}
              {/*    style={{ width: "min-content" }}*/}
              {/*    className={*/}
              {/*      dekorAdd*/}
              {/*        ? "addToDrawerDekor active dekor2"*/}
              {/*        : "addToDrawerDekor dekor2"*/}
              {/*    }*/}
              {/*  >*/}
              {/*    +1*/}
              {/*  </span>*/}
              {/*  <span*/}
              {/*    style={{ width: "min-content" }}*/}
              {/*    className={*/}
              {/*      dekorAdd*/}
              {/*        ? "addToDrawerDekor active dekor3"*/}
              {/*        : "addToDrawerDekor dekor3"*/}
              {/*    }*/}
              {/*  >*/}
              {/*    +1*/}
              {/*  </span>*/}

              {/*  {addCart}*/}
              {/*</span>*/}
              {/*<button*/}
              {/*    className={textFavorite ? "added" : ""}*/}
              {/*    onClick={clickToFavorite}*/}
              {/*>*/}
              {/*    {textFavorite ? deleteFavorite : addFavorite}*/}
              {/*</button>*/}
            </div>
            {/*{textFavorite ? <img onClick={clickToFavorite} className={"card_favorite"}*/}
            {/*                     src={"/img/iconHeart.svg"}*/}
            {/*                     style={{color: "red"}}/>*/}
            {/*    : <img onClick={clickToFavorite} className={"card_favorite"}*/}
            {/*           src={"/img/iconHeartRed.svg"}*/}
            {/*           style={{color: "red"}}/>}*/}
          </div>

          <h4>{product?.attributes?.title}</h4>

          {product?.attributes?.oldPrice && !profile ? (
            <div className="d-flex a-i-end change-price">
              <h5 className="old">
                {changeCurrency(product?.attributes?.oldPrice, currency)}
              </h5>
              <h5 className="new">
                {changeCurrency(product?.attributes?.price, currency)}{" "}
              </h5>
            </div>
          ) : typeof product?.attributes?.PersonalAccountDiscountPercent ===
              "number" && profile ? (
            <h5>
              {changeCurrency(
                (
                  returnPriceOrFalsePrice() *
                  ((100 - product?.attributes?.PersonalAccountDiscountPercent) /
                    100)
                ).toFixed(0),
                currency,
              )}
            </h5>
          ) : (
            <h5>{changeCurrency(returnPriceOrFalsePrice(), currency)}</h5>
          )}

          {/*<Link*/}
          {/*  className="card__addToDrawer"*/}
          {/*  to={"/product/" + url + "/" + product.id}*/}
          {/*>*/}
          {/*  {addCart}*/}
          {/*</Link>*/}
          {/*<button*/}
          {/*    onClick={clickToFavorite}*/}
          {/*    className={*/}
          {/*        textFavorite*/}
          {/*            ? "added favorite__button-mobile"*/}
          {/*            : "favorite__button-mobile"*/}
          {/*    }*/}
          {/*    onClick={clickToFavorite}*/}
          {/*>*/}
          {/*    {textFavorite ? deleteFavorite : addFavorite}*/}
          {/*</button>*/}
        </div>
      ) : (
        <>
          <div
            onMouseOver={() => setControlPanel(true)}
            onMouseOut={() => setControlPanel(false)}
            className="card"
          >
            <div className="card__img-wr">
              <div
                className={
                  skeletonState
                    ? "skeleton-load-img active"
                    : "skeleton-load-img"
                }
              >
                <img src={"/img/header__logo-short.png"} />
              </div>
              {product.uniqId}
              <Link to={"/favorites/" + getId(product) + "/" + product.uniqId}>
                <img
                  onClick={() => {
                    setControlPanel(!controlPanel);
                  }}
                  src={
                    process.env.REACT_APP_IMG +
                    product.img.replaceAll(process.env.REACT_APP_IMG, "")
                  }
                  alt=" "
                  className="main"
                  onLoad={() => {
                    setSkeletonState(false);
                  }}
                />
              </Link>

              <div
                className={
                  controlPanel ? "card__controls active" : "card__controls"
                }
              >
                {/*<Link to={"/product/" + url + "/" + product.id}>{addCart}</Link>*/}
                <button
                  // className={textFavorite ? "added" : ""}
                  onClick={clickToFavorite}
                >
                  {/*{textFavorite ? deleteFavorite : addFavorite}*/}
                  {deleteFavorite}
                </button>
              </div>
            </div>
            <h4>{product.title}</h4>
            <h5>{changeCurrency(product?.price, currency)}</h5>

            <Link
              className="card__addToDrawer"
              to={"/product/" + url + "/" + getId(product)}
            >
              {addCart}
            </Link>
          </div>
        </>
      )}
    </>
  );
}

export default Card;
