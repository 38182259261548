import React, {useEffect} from 'react';
import './configuratorElementShort.scss';

import {useSelector} from "react-redux";

function ConfiguratorElementShort({title, arr, id, calculateSumModules, wishId}) {
    arr = arr.split(';');
    const removePriceFromText = (text) => {
        return text.replace(/\[[-=+]?\d+\]/g, ''); // Видаляємо числа в квадратних дужках
    };
    const [currentIndex, setCurrentIndex] = React.useState(!wishId ? 0 : -1);
    const [currentMutationPrice, setCurrentMutationPrice] = React.useState(0);
    const [currentValue, setCurrentValue] = React.useState(!wishId ? removePriceFromText(arr[0]) : '');

    React.useEffect(() => {
        //  console.log(id, currentMutationPrice.toString(), title, currentValue, {title, arr, id, calculateSumModules, wishId});

        calculateSumModules(id, currentMutationPrice.toString(), title, currentValue);
    }, [currentMutationPrice]);

    const wishArray = useSelector(state => state.favorite.favorite)

    useEffect(() => {
        if (!wishId) return
        if (wishArray.length == 0 || !wishArray) return
        const findedProduct = wishArray.find(element => element.uniqId.toString() === wishId.toString());
        if (!findedProduct) {
            return
        }
        const module = findedProduct.configurations.find(element => {
            return element.id == id
        })
        // console.log(module);
        // console.log(module);A
        //   console.log(12312321132321);
        if (module.value.length < 1) return
        let i = 0;
        arr.forEach((element, index) => {
            //   console.log('---' + module.value.replaceAll(" ", "").replaceAll("\n", ''), element.replace(/\[[\d+-]{0,10}\]/g, '').replaceAll(" ",
            //   "").replaceAll("\n", ''));
            if (module.value.replaceAll(" ", "").replaceAll("\n", '') == element.replace(/\[[\d+-]{0,10}\]/g, '').replaceAll(" ", "").replaceAll("\n", '')) {

                //     console.log(module.value, "nowe id ", i);

                changeElement({
                    target: {
                        innerText: module.value,
                        dataset: {
                            priceMutation: module.price
                        }
                    }

                }, i)
            }
            i++
        })
    }, [wishId, wishArray]);//

    useEffect(() => {
        console.log(currentIndex);
    }, [currentIndex]);
    const extractNumber = (str) => {
        const startIndex = str.indexOf('[');
        const endIndex = str.indexOf(']');

        if (startIndex !== -1 && endIndex !== -1) {
            const numberStr = str.substring(startIndex + 1, endIndex);
            const number = parseInt(numberStr);
            return isNaN(number) ? null : number;
        }

        return null;
    }

    const changeElement = (e, index) => {
        setCurrentIndex(index);

        setCurrentValue(e.target.innerText);
        setCurrentMutationPrice(e.target.dataset.priceMutation);
    }


    return (
        <div className='short__wr'>
            <h4>{title}</h4>
            <ul>

                {arr.map((el, index) => {
                    const priceMutation = extractNumber(el);

                    return (
                        <li
                            key={el + index + 'short'}
                            onClick={(e) => {
                                changeElement(e, index)
                            }}
                            className={currentIndex == index ? 'active' : ''}
                            data-price-mutation={priceMutation}
                        >
                            {removePriceFromText(el)}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default ConfiguratorElementShort;
